import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"  

export const getroleUserListCustom = createAsyncThunk('role-user-list', async (data) => { 
    try {
        const res = await useJwt.getUserRoleList({
                limit: data.pageNo,
                page: data.page,
                search: data.search
            })
        return res.data
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getRoleUserMethod = createSlice({
    name: 'roleUserListData',
    initialState: {
        loading: false,
        roleUserList: []
    },
    reducers: {
        getRoleUser(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getroleUserListCustom.fulfilled]: (state, action) => {
            state.roleUserList = action.payload
            state.loading = false
        },
        [getroleUserListCustom.pending]: (state) => {
            state.loading = true
        },
        [getroleUserListCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { getRoleUser } = getRoleUserMethod.actions
export default getRoleUserMethod.reducer

