import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import axios from "axios"


export const registerCustom = createAsyncThunk('register', async (fieldValue) => {
    try {
        const res = await axios(useJwt.jwtConfig.register, {
            method: 'POST',
            data: fieldValue,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})
export const loginCustom = createAsyncThunk('login', async (fieldValue) => {
    try {
        const res = await axios(useJwt.jwtConfig.login, {
            method: 'POST',
            data: fieldValue,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data


    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const registerMethod = createSlice({
    name: 'registerData',
    initialState: {
        loading: false,
        userRegister: {}
    },
    reducers: {
        register(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [registerCustom.fulfilled]: (state, action) => {
            state.userRegister = action.payload
            state.loading = false
        },
        [registerCustom.pending]: (state) => {
            state.loading = true
        },
        [registerCustom.rejected]: (state) => {
            state.loading = false
        },
        [loginCustom.fulfilled]: (state, action) => {
            state.userRegister = action.payload
            state.loading = false
        },
        [loginCustom.pending]: (state) => {
            state.loading = true
        },
        [loginCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { register } = registerMethod.actions
export default registerMethod.reducer

