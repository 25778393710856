import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"  

export const getCompanyListCustom = createAsyncThunk('get-company-list', async (data) => {
    try {
        if (data.type === 'list') {
            const res = await useJwt.getCompanyList({
                    limit: data.pageNo,
                    page: data.page,
                    name: data.search,
                    status: 1
                })
            return res.data
        } else {
            const res = await useJwt.getCompanyList({
                    limit: data.pageNo,
                    page: data.page,
                    search: data.search
                })
            return res.data
        }
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getCompanyListMethod = createSlice({
    name: 'companyListData',
    initialState: {
        loadingComp: false,
        companyList: []
    },
    reducers: {
        getCompanyList(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getCompanyListCustom.fulfilled]: (state, action) => {
            state.companyList = action.payload
            state.loadingComp = false
        },
        [getCompanyListCustom.pending]: (state) => {
            state.loadingComp = true
        },
        [getCompanyListCustom.rejected]: (state) => {
            state.loadingComp = false
        }
    }
})

export const { getCompanyList } = getCompanyListMethod.actions
export default getCompanyListMethod.reducer

