import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt" 

export const getUserProfileCustom = createAsyncThunk('user-Profile', async (data) => {
    try {
        if (data.type === 'getuser') {
            const res = await useJwt.getUserProfile(data.userId)
            return res.data
        }
        if (data.type === 'approve') {
            const res = await useJwt.updateUserProfile(data.userId, {status:data.status})
            return res.data
        }
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const getProfileMethod = createSlice({
    name: 'userProfile',
    initialState: {
        loading: false,
        userProfileData: {}
    },
    reducers: {
        getProfile(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getUserProfileCustom.fulfilled]: (state, action) => {
            state.userProfileData = action.payload
            state.loading = false
        },
        [getUserProfileCustom.pending]: (state) => {
            state.loading = true
        },
        [getUserProfileCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { getProfile } = getProfileMethod.actions
export default getProfileMethod.reducer

