// approvalgroup

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt" 

export const getApprovalGroups = createAsyncThunk("get-launch-pads", async (data) => {
    try {
        if (data.type === 'list') {
            const res = await useJwt.getApprovalGroups({
                    limit: 50,
                    page: data.page,
                    name: data.search,
                    company: data.company
                })
            return res.data
        } else {
            const res = await useJwt.getApprovalGroups({
                    limit: data.pageNo,
                    page: data.page,
                    search: data.search
                })
            return res.data
        }
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}
)

const getApprovalGroupsMethod = createSlice({
    name: "approvalGroups",
    initialState: {
        loadingComp: false,
        approvalGroups: []
    },
    reducers: {
        getApprovalGroupList(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getApprovalGroups.fulfilled]: (state, action) => {
            state.approvalGroups = action.payload
            state.loadingComp = false
        },
        [getApprovalGroups.pending]: (state) => {
            state.loadingComp = true
        },
        [getApprovalGroups.rejected]: (state) => {
            state.loadingComp = false
        }
    }
})

export const { getApprovalGroupList } = getApprovalGroupsMethod.actions
export default getApprovalGroupsMethod.reducer
