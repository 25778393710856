import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt" 

export const getLaunchPads = createAsyncThunk(
  "get-launch-pads",
  async (data) => {
    try {
      if (data.type === "list") {
        const res = await useJwt.getLaunchPags({
            limit: data.pageNo,
            page: data.page,
            search: data.search
          })
        return res.data
      } else {
        const res = await useJwt.getLaunchPags({
            limit: data.pageNo,
            page: data.page,
            search: data.search
          })
        return res.data
      }
    } catch (error) {
      if (error.response) {
        return error.response.data
      }
    }
  }
)

const getLaunchPadsMethod = createSlice({
  name: "launchPads",
  initialState: {
    loadingComp: false,
    launchPads: []
  },
  reducers: {
    getLaunchPadList(state, action) {
      state = action.payload
    }
  },
  extraReducers: {
    [getLaunchPads.fulfilled]: (state, action) => {
      state.launchPads = action.payload
      state.loadingComp = false
    },
    [getLaunchPads.pending]: (state) => {
      state.loadingComp = true
    },
    [getLaunchPads.rejected]: (state) => {
      state.loadingComp = false
    }
  }
})

export const { getLaunchPadList } = getLaunchPadsMethod.actions
export default getLaunchPadsMethod.reducer
