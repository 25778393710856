import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"  

export const forgotPasswordCustom = createAsyncThunk('forgotPassword', async (email) => {
    try {
        const res = await useJwt.forgotPassword({email})
        return res.data
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const forgotPasswordMethod = createSlice({
    name: 'forgotPassword',
    initialState: {
        loading: false,
        forgotPasswordRes: {}
    },
    reducers: {
        forgotPasswordRed(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [forgotPasswordCustom.fulfilled]: (state, action) => {
            state.forgotPasswordRes = action.payload
            state.loading = false
        },
        [forgotPasswordCustom.pending]: (state) => {
            state.loading = true
        },
        [forgotPasswordCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { forgotPasswordRed } = forgotPasswordMethod.actions
export default forgotPasswordMethod.reducer

