// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
 
 
export const reviewSlice = createSlice({
  name: 'review',
  initialState: {
     reviewFormData : {},
     templates : []
  },
  reducers: { 
    addReviewFormData : (state, action) => {   
      const data = action.payload.data
      const isAchievements = action.payload.isAchievements
      let newPayload = {}
      if (isAchievements) { 
        const achievementPayload = state.reviewFormData['achievements'] || {}
        newPayload['achievements'] = { ...achievementPayload, ...data }  
      } else {
        newPayload = {
          ...state.reviewFormData,
          ...data
        }
      } 
      state.reviewFormData = {...state.reviewFormData, ...newPayload}
    },
    resetReviewFormData : (state) => {
      state.reviewFormData = {}
    },
    addTemplates : (state, action) => {
      state.templates = action.payload
    }
  }
})

export const {addReviewFormData, addTemplates, resetReviewFormData}  = reviewSlice.actions
export default reviewSlice.reducer
