// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import todo from "@src/views/apps/todo/store"
import chat from "@src/views/apps/chat/store"
import users from "@src/views/apps/user/store"
import email from "@src/views/apps/email/store"
import kanban from "@src/views/apps/kanban/store"
import invoice from "@src/views/apps/invoice/store"
import calendar from "@src/views/apps/calendar/store"
import ecommerce from "@src/views/apps/ecommerce/store"
import dataTables from "@src/views/tables/data-tables/store"
import permissions from "@src/views/apps/roles-permissions/store"
import rolesList from "../redux/getRole"
import registerData from "./registerRedux"
import forgotPassword from "./forgotPasswordRedux"
import optRes from "./otpRedux"
import userProfile from "./profileRedux"
import empIdData from "./getEmpIdRedux"
import createEmpData from "./createEmployee"
import roleUserListData from "./getRoleUserList"
import companyListData from "./getCompanyList"
import launchPadsListData from "./getLaunchPads"
import departmentListData from "./getDepartment"
import approvalGroups from "./getApprovalGroup"
import notificationList from "./getNotification"
import reviewData from "../views/admin/components/reviews/store"

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  rolesList,
  registerData,
  forgotPassword,
  optRes,
  userProfile,
  empIdData,
  createEmpData,
  roleUserListData,
  companyListData,
  launchPadsListData,
  departmentListData,
  approvalGroups,
  notificationList,
  reviewData
}

export default rootReducer
