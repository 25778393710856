import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"  

export const getNotificationCustom = createAsyncThunk('getNotification', async () => {
    try {
        const res = await useJwt.getNotification()
        return res?.data
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }
})

export const getNotificationUnRead = createAsyncThunk('getNotificationUnRead', async () => {
    try {
        const res = await useJwt.getNotificationUnread()
        return res?.data
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }
})
export const getNotificationRead = createAsyncThunk('getNotificationRead', async () => {
    try {
        const res = await useJwt.getNotificationRead()
        return res?.data
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }
})

const getNotificationMethod = createSlice({
    name: 'notificationList',
    initialState: {
        loadingList: false,
        notification: {},
        notificationUnRead: {},
        notificationRead: {}
    },
    reducers: {
        getNotification(state, action) {
            state = action.payload
        },
        getNotificationUnRead(state, action) {
            state = action.payload
        },
        getNotificationRead(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getNotificationCustom.fulfilled]: (state, action) => {
            state.notification = action.payload
            state.loading = false
        },
        [getNotificationCustom.pending]: (state) => {
            state.loading = true
        },
        [getNotificationCustom.rejected]: (state) => {
            state.loading = false
        },
        [getNotificationUnRead.fulfilled]: (state, action) => {
            state.notificationUnRead = action.payload
            state.loading = false
        },
        [getNotificationRead.fulfilled]: (state, action) => {
            state.notificationRead = action.payload
            state.loading = false
        }
    }
})

export const { getRoles } = getNotificationMethod.actions
export default getNotificationMethod.reducer

