import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"  

export const createEmpCustom = createAsyncThunk('craete-employee', async (empData) => {
    try {
        if (empData?.type === "add") {
            const res = await useJwt.createUser(empData.item)
            return res.data
        }
        if (empData?.type === "update") {
            const res = await useJwt.updateUserProfile(empData.id, empData.item)
            return res.data
        }
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const createEmpMethod = createSlice({
    name: 'createEmpData',
    initialState: {
        loading: false,
        createEmp: {}
    },
    reducers: {
        createEmpRed(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [createEmpCustom.fulfilled]: (state, action) => {
            state.createEmp = action.payload
            state.loading = false
        },
        [createEmpCustom.pending]: (state) => {
            state.loading = true
        },
        [createEmpCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { createEmpRed } = createEmpMethod.actions
export default createEmpMethod.reducer

