import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import axios from "axios"


export const otpVerifiedCustom = createAsyncThunk('otpVerified', async (fieldValue) => {
    try {
        if (fieldValue.type === 'verified') {
            const res = await axios(useJwt.jwtConfig.otpVerified, {
                method: 'POST',
                data: { otp: fieldValue.data },
                headers: {
                    Authorization: `Bearer ${fieldValue.token}`,
                    'Content-Type': 'application/json'
                }
            })
            return res.data
        }
        if (fieldValue.type === 'resend') {
            const res = await axios(useJwt.jwtConfig.otpResend, {
                method: 'POST',
                data: { email: fieldValue.data },
                headers: {
                    Authorization: `Bearer ${fieldValue.token}`,
                    'Content-Type': 'application/json'
                }
            })
            return res.data
        }

    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }
})

const otpVerifiedMethod = createSlice({
    name: 'optRes',
    initialState: {
        loading: false,
        otpData: {}
    },
    reducers: {
        otpVerifiedRed(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [otpVerifiedCustom.fulfilled]: (state, action) => {
            state.otpData = action.payload
            state.loading = false
        },
        [otpVerifiedCustom.pending]: (state) => {
            state.loading = true
        },
        [otpVerifiedCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { otpVerifiedRed } = otpVerifiedMethod.actions
export default otpVerifiedMethod.reducer

