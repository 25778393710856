import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt" 

export const getEmpIdCustom = createAsyncThunk('employee', async (empId) => {
    try {
        if (empId) {
            const res = await useJwt.getEmployee(`?empId=${empId.empId}&ssnNo=${empId.ssnNumber}`)
            return res.data
        } else {
            const res = await useJwt.getEmployee('')
            return res.data
        }
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getEmpIdMethod = createSlice({
    name: 'empIdData',
    initialState: {
        loading: false,
        empData: {}
    },
    reducers: {
        getEmpId(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getEmpIdCustom.fulfilled]: (state, action) => {
            state.empData = action.payload
            state.loading = false
        },
        [getEmpIdCustom.pending]: (state) => {
            state.loading = true
        },
        [getEmpIdCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { getEmpId } = getEmpIdMethod.actions
export default getEmpIdMethod.reducer

