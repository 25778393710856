import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          localStorage.clear()
          location.reload()
          // if (!this.isAlreadyFetchingAccessToken) {

          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false
          //     // ** Update accessToken in localStorage
          //     this.setToken(r.data.access.token)
          //     this.setRefreshToken(r.data.refresh.token)
          //     this.onAccessTokenFetched(r.data.access.token)
          //     location.reload()
          //   })
          // }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              // resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }
  
  logout() {
    const refreshToken = this.getRefreshToken()
    return axios.post(this.jwtConfig.logout, { refreshToken })
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  forgotPassword(data) {
    return axios(this.jwtConfig.forgotPassword, {
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
  }
  getHistory(isSelf, params) {
    const url = isSelf ? this.jwtConfig.ownHistory : this.jwtConfig.employeeHistory
    return axios.get(`${url}?${params}`)
  }

  createOrUpdateReview(payload) { 
    return axios.post(this.jwtConfig.addReview, payload)
  }
  
  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  // approval groups =====================================================
  createApprovalGroup(data) {
    return axios.post(this.jwtConfig.approvalgroup, data)
  }
  editApprovalGroup(id, data) {
    return axios(`${this.jwtConfig.approvalgroup  }/${id}`, {
                    method: 'PATCH',
                    data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
    
  }
  getApprovalGroups(params) {
    return axios.get(this.jwtConfig.approvalgroup, { params })
  }
  getApprovalGroup(id) {
    return axios.get(`${this.jwtConfig.approvalgroup}/${id}`)
  }
  getApprovalGroupListCompanyWise(companyId) {
    return axios.get(`${this.jwtConfig.getApprovalCompanygroup}/${companyId}`)
  }
  getApprovalGroupList(id) {
    return axios.get(`${this.jwtConfig.approvalgroupTemplateEndPoint}/${id}`)
  }
  deleteApprovalGroup(id) {
    return axios(`${this.jwtConfig.approvalgroup}/${id}`,
                  {
                      method: "DELETE",
                      headers: {
                          "Content-Type": "application/json"
                      }
                  })
  }


  // company list  ===================================================== 
  getCompanyList(params) {
    return axios.get(this.jwtConfig.addCompany, {params})
  }
  addCompany(payload) {
    return axios(this.jwtConfig.addCompany, payload)
  }
  updateCompany(id, data) {
    return axios(`${this.jwtConfig.addCompany}/${id}`, {
                  method : "PATCH",
                  data,
                  headers: {
                    "Content-Type": "application/json"
                  }
                })
  }
  deleteCompany(id) {
    return axios(`${this.jwtConfig.addCompany}/${id}`,
                  {
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json"
                    }
                  }
                )
  }
  uploadCompanyDoc(formData) {
    return axios.post(`${this.jwtConfig.addCompany  }/upload`, formData)
  }
  downloadCompanyDoc() {
    return axios({
                  url: `${this.jwtConfig.addCompany  }/download`,
                  method: "GET",
                  responseType: "blob"
                })
  }


  // departments =====================================================
  getDepartments(params) {
    return axios.get(this.jwtConfig.addDepartment, {params})
  }
  getEmployeeListOfDepartment(companyId, departmentId, nextLevelApprovalGroup) {
    return axios.get(`${this.jwtConfig.employeetList}/${companyId}/department/${departmentId}/${nextLevelApprovalGroup}`)
  }
  getDepartmentListCompanyWise(companyId) {
    return axios.get(`${this.jwtConfig.depertmentList}/${companyId}`)
  }
  addDepartment(payload) {
    return axios(this.jwtConfig.addDepartment, payload)
  }
  updateDepartment(id, data) {
    return axios(`${this.jwtConfig.addDepartment}/${id}`,
                    {
                      method: "PATCH",
                      data,
                      headers: {
                        "Content-Type": "application/json"
                      }
                    }
                  )
  }
  deleteDepartment(id) {
    return axios(`${this.jwtConfig.addDepartment}/${id}`,
                  {
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json"
                    }
                  }
                )
  }
  uploadDepartDocs(formData) {
    return axios.post(`${this.jwtConfig.addDepartment}/upload`, formData)
  }
  downloadDepartmentDocs() {
    return axios({
            url: `${this.jwtConfig.addDepartment  }/download`,
            method: "GET",
            responseType: "blob" // important
          })
  }


  //employee =====================================================
  getEmployee(query) {
    return axios.get(`${this.jwtConfig.employee}${query}`)
  }
  getAllEmployeeList(query) {
    return axios.get(`${this.jwtConfig.allEmployeesList}${query}`)
  }
  getEmployeeReviewList(query) {
    return axios.get(`${this.jwtConfig.employeeReview}${query}`)
  }
  reviewedByManagerReviewList(query) {
    return axios.get(`${this.jwtConfig.asManagerEndpoint}${query}`)
  }
  getEmployeeReviewedList(templateId, reviewId) {
    return axios.get(`${this.jwtConfig.employeeReviewedList}/${templateId}/${reviewId}`)
  }
  employeeReviewedRatings(id, approvalGroup, data) {
    return axios.post(`${this.jwtConfig.employeeReviewedRatings}/${id}/${approvalGroup}`, data)
  }

  
  //manager =====================================================
  getManagerDashboard() {
    return axios.get(this.jwtConfig.managerDashboardReviews)
  }
  getAllEmployeesManagerList(query) {
    return axios.get(`${this.jwtConfig.managerReviewAssignedEmployees}${query}`)
  }
  getManangerSubmitedReview(query) {
    return axios.get(`${this.jwtConfig.managerReviewSubmitedReviews  }${query}`)
  }
  getEmployeedReviewedRatings(templateId, reviewId, data) {
    return axios.post(`${this.jwtConfig.employeeReviewedRatings}/${templateId}/${reviewId}`, data)
  }
  deleteTemplateRating(id) {
    return axios(`${this.jwtConfig.deleteTemplateRating}/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                  }
                )
  }
  duplicateTemplate(data) {
    return axios.post(this.jwtConfig.duplicateTemplate, data)
  }
  getManagerAllReviews(query) {
    return axios.get(`${this.jwtConfig.managerReviewAllReviews}${query}`)
  }
  getManagerSubmittedReview(query) {
    return axios.get(`${this.jwtConfig.managerReviewSubmitedReviews}${query}`)
  }
  getManagerOwnReviews(query) {
    return axios.get(`${this.jwtConfig.managerReviewAsMangerReviews}${query}`)
  }
  managerReviewReminder(id) {
    return axios(`${this.jwtConfig.managerReviewSendReminder}/${id}`,
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          }
      })
  }
  getManagerOpenReview(query) {
    return axios.get(`${this.jwtConfig.managerReviewOpenReviews}${query}`)
  }


  //launchpads =====================================================
  getLaunchPags(params) {
    return axios.get(this.jwtConfig.getUserLaunchPads, {params})
  }
  addLaunchPad(formData) {
    return axios.patch(this.jwtConfig.launchpad, formData)
  }
  deleteLaunchPad(id) {
    return axios(`${this.jwtConfig.launchpad}/${id}`, {
                      method: "DELETE",
                      headers: {
                        "Content-Type": "application/json"
                      }
                    })
  }


  //notifications =====================================================
  getNotification() {
    return axios.get(this.jwtConfig.notification)
  }
  getNotificationUnread() {
    return axios.get(this.jwtConfig.notificationUnRead)
  }
  getNotificationRead() {
    return axios.put(this.jwtConfig.notificationRead)
  }
  getNotificationReadTemplateAndReviewWise(templateId, reviewId) {
    return axios.put(`${this.jwtConfig.notificationRead}/${templateId}/${reviewId}`)
  }


  //roles =====================================================
  getRoles() {
    return axios.get(this.jwtConfig.getRoles)
  }
  getUserRoleList(params) {
    return axios.get(this.jwtConfig.roleUserList, {params})
  }
  getAllRoles() {
    return axios.get(this.jwtConfig.getAllRoles)
  }
  getEmployeeRoles() {
    return axios.get(this.jwtConfig.employeeRoleTypes)
  }


  //user profile =====================================================
  getUserProfile(id) {
    return axios.get(`${this.jwtConfig.userProfile}/${id}`)
  }
  createUser(data) {
    return axios(this.jwtConfig.createUsers, {
      method: 'POST',
      data,
      headers: {
          'Content-Type': 'application/json'
      }
  })
  }
  updateUserProfile(id, data) {
    return axios(`${this.jwtConfig.userProfile}/${id}`, {
      method: 'PATCH',
      data,
      headers: {
          'Content-Type': 'application/json'
      }
  })
  }
  deleteUser(id) {
    return axios(`${this.jwtConfig.userProfile}/${id}`, {
                      method: "DELETE",
                      headers: {
                        "Content-Type": "application/json"
                      }
                    })
  }
  uploadUsersDoc(formData) {
    return axios.post(this.jwtConfig.uploadUsers, formData)
  }


  //reviews =====================================================
  downloadReviewManager(reviewId, type, payload) {
    return axios.post(`${this.jwtConfig.genrateTemplateReviews}/${reviewId}`, type, payload)
  }
  downloadReviewEmployee(templateId, reviewId, type, payload) {
    return axios.post(`${this.jwtConfig.genrateTemplateReviews}/${templateId}/${reviewId}`, type, payload)
  }
  getEmployeeReviewCompleted(params) {
    return axios.get(this.jwtConfig.employeeReviewCompletedEndPoint, {params})
  }
  getEmployeeReviewOpen(params) {
    return axios.get(this.jwtConfig.employeeReviewOpenedEndPoint, {params})
  }

  editReview(reviewId, form) {
    return axios.patch(`${this.jwtConfig.employeeReview}/${reviewId}`, form)
  }
  getReview(reviewId) {
    return axios.get(`${this.jwtConfig.employeeReview}/${reviewId}`)
  }
  sendReviewReminder(templateId, reviewId) {
    return axios(`${this.jwtConfig.managerReviewSendReminder}/${templateId}/${reviewId}`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json"
                      }
                    })
  }
  updateReviewStatus(id, statusData) {
    return axios(`${this.jwtConfig.employeeReviewUpdateReviewStatus}/${id}`,
                      {
                          method: "POST",
                          headers: {
                              "Content-Type": "application/json"
                          },
                          data: statusData
                      }
                  )
  }
  addComment(data) {
    return axios.post(this.jwtConfig.addCommentOnRating, data)
  }
  getReviewDetails(data){
    return axios.post(this.jwtConfig.reviewDetails , data)
  }


  //review meetings =====================================================
  getReviewMeetings() {
    return axios.get(this.jwtConfig.employeeUpcomingMeetings)
  }   


  //templates =====================================================
  addTemplate(data) {
    return axios.post(this.jwtConfig.addTemplateRating, data)
  }
  getTemplates(query) {
    return axios.get(`${this.jwtConfig.templateRating}${query}`)
  } 
  editTemplate(id, data) {
    return axios.patch(`${this.jwtConfig.deleteTemplateRating}/${id}`, data)
  }
  getTemplateDataDocWise(templateId, docId) {
    return axios.get(`${this.jwtConfig.deleteTemplateRating}/${templateId}/${docId}`)
  } 
  getTemplate(tepmlateId) {
    return axios.get(`${this.jwtConfig.deleteTemplateRating}/${tepmlateId}`)
  }
  deleteTemplateRating(id) {
    return  axios(`${this.jwtConfig.deleteTemplateRating}/${id}`,
                {
                  method: "DELETE",
                  headers: {
                      "Content-Type": "application/json"
                  }
                }
            )
  }
  getAssignedEmployeesList(query) {
    return axios.get(`${this.jwtConfig.managerReviewAssignedEmployees}${query}`)
  }

  submitComment(data) {
    return axios.post(this.jwtConfig.addCommentOnRating, data)
  }
}
