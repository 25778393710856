import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt" 

export const getDepartmentListCustom = createAsyncThunk('get-department-list', async (data) => {
    try {
        if (data.type === 'list') {
            const res = await useJwt.getDepartments({
                    limit: data.pageNo,
                    page: data.page,
                    name: data.search,
                    status: 1
                })
            return res.data
        } else {
            const res = await useJwt.getDepartments({
                    limit: data.pageNo,
                    page: data.page,
                    search: data.search
                })
            return res.data
        }
    } catch (error) {
        if (error.response) {
            return error.response.data 
        }
    }

})


const getDepartmentListMethod = createSlice({
    name: 'departmentListData',
    initialState: {
        loading: false,
        departmentList: []
    },
    reducers: {
        getDepartmentList(state, action) {
            state = action.payload
        },
        clearDepartmentList(state) {
            state.departmentList = []
        }
    },
    extraReducers: {
        [getDepartmentListCustom.fulfilled]: (state, action) => {
            state.departmentList = action.payload
            state.loading = false
        },
        [getDepartmentListCustom.pending]: (state) => {
            state.loading = true
        },
        [getDepartmentListCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { getDepartmentList, clearDepartmentList } = getDepartmentListMethod.actions

export default getDepartmentListMethod.reducer

