import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"  

export const getRolesCustom = createAsyncThunk('getRoles', async () => {
    try {
        const res = await useJwt.getRoles()
        return res.data.result
    } catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getRolesMethod = createSlice({
    name: 'rolesList',
    initialState: {
        loadingList: false,
        roles: {}
    },
    reducers: {
        getRoles(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getRolesCustom.fulfilled]: (state, action) => {
            state.roles = action.payload
            state.loading = false
        },
        [getRolesCustom.pending]: (state) => {
            state.loading = true
        },
        [getRolesCustom.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { getRoles } = getRolesMethod.actions
export default getRolesMethod.reducer

